
<script setup>
    import { watch, inject } from "vue";

    const props = defineProps({
        name: { type: String, required: true  },
        text: { type: String, required: true  },
        icon: { type: String, required: false },
    });

    const tab = inject("tab");
</script>

<template>
    <button :class="{ 'selected': tab === props.name }" @click="tab = props.name">
        <template v-if="props.icon">
            <i :class="props.icon" style="margin-bottom: -0.25rem;" />
        </template>
        <span style="margin-top: 0.25rem; font-size: 0.9em;">{{ props.text }}</span>
    </button>
</template>

<style scoped>
    button {
        flex: 1;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        padding: 0;

        color: var(--bs-gray-600);
        background: var(--bs-white);
        border: none;

        transition: all 100ms ease-in-out;

        &:not(:last-child) {
            border-right: 1px solid var(--bs-border-color);
        }

        &.selected {
            color: var(--bs-black);
            background: var(--bs-gray-200);
        }

        &:active {
            transition: all 0ms;
        }
    }
</style>
